import axios from "axios";
import { t } from "i18next";

import config from "../config/config";
import { DesignErrorHandler } from "../errors/design";
import { isServiceError, ServiceError } from "../errors/types";
import {
    DesignState, Dimensions, getDefaultDesignState,
} from "../states/design";
import { Analysis, CompatiblePrintOptions } from "../states/print";
import { getAlias } from "../utils/file";

export class DesignService {
    static async uploadParts (designId: string, file: File, fileAlias: string, progressHandler: (fileAlias: string, progressIncrease: number) => void): Promise<void | ServiceError> {
        const response = await this.getPartUploadPresignedUrls(designId);
        if (isServiceError(response)) {
            return response;
        }

        const numOfChunks = response["numOfChunks"];
        const maxChunkSize = response["maxChunkSize"];
        const presignedUrls = response["presignedUrls"];
        const numBatches = Math.ceil(numOfChunks / config.designs.simultaneousPartUploads);

        for (let batch = 0; batch < numBatches; batch++) {
            let partPromises = [];

            for (let batchPartIdx = 0; batchPartIdx < config.designs.simultaneousPartUploads; batchPartIdx++) {
                const partIdx = batch * config.designs.simultaneousPartUploads + batchPartIdx;

                if (partIdx >= numOfChunks) {
                    break;
                }

                const partUrl = presignedUrls[partIdx];
                const start = partIdx * maxChunkSize;
                const end = Math.min((partIdx + 1) * maxChunkSize, file.size);

                const chunk = file.slice(start, end);

                partPromises.push(
                    this.uploadPart(chunk, partUrl)
                        .then(() => { progressHandler(fileAlias, 100 / (numOfChunks + 1)); })
                        .catch((error) => { return error; }),
                );
            }

            const uploadPartResponses = await Promise.all(partPromises);
            for (const response of uploadPartResponses) {
                if (isServiceError(response)) {
                    return response;
                }
            }
            partPromises = [];
        }

        const completeUploadResponse = await this.completeUpload(designId);
        if (isServiceError(completeUploadResponse)) {
            return completeUploadResponse;
        }
        progressHandler(fileAlias, 100 / (numOfChunks + 1));
    }

    static async initUpload (orderId: string, file: File): Promise<DesignState | ServiceError> {
        const fileAlias = getAlias(file.name);
        const designState = getDefaultDesignState(file);

        return await axios.post(`${ process.env.REACT_APP_BACKEND_HOST }/design/${ orderId }/upload/init`, {
            fileName: fileAlias,
            totalSize: file.size,
        })
            .then((response) => {
                const design = response.data;

                return {
                    ...designState,
                    id: design._id,
                    isUploading: true,
                    uploadProgress: 0,
                    error: null,
                    fileAlias: design.fileAlias,
                } as DesignState;
            })
            .catch((error) => {
                return DesignErrorHandler.initUpload(error);
            });
    }

    private static async getPartUploadPresignedUrls (designId: string): Promise<{
        numOfChunks: number,
        maxChunkSize: number,
        presignedUrls: string[]
    } | ServiceError> {
        return await axios.get(`${ process.env.REACT_APP_BACKEND_HOST }/design/${ designId }/upload/part-urls`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return DesignErrorHandler.getPartUploadPresignedUrls(error);
            });
    }

    private static async uploadPart (content: Blob, presignedUrl: string): Promise<void | ServiceError> {
        const maxRetries = (process.env.REACT_APP_MAX_REQUEST_RETRIES || 1) as number;
        let retries = 0;

        while (retries < maxRetries) {
            const response = await axios.put(presignedUrl, content, {
                headers: {
                    "content-type": "application/octet-stream",
                },
            })
                .then(() => { return; })
                .catch((error) => {
                    return DesignErrorHandler.uploadPart(error);
                });

            if (!isServiceError(response)) {
                return;
            }

            if (isServiceError(response) && retries >= maxRetries) {
                return response;
            }

            retries++;
        }
    }

    private static async completeUpload (designId: string): Promise<void | ServiceError> {
        return await axios.post(`${ process.env.REACT_APP_BACKEND_HOST }/design/${ designId }/upload/complete`)
            .then(() => { return; })
            .catch((error) => {
                return DesignErrorHandler.completeUpload(error);
            });
    }

    /**
     * Retrieves the public url to the design.
     *
     * @param designId - The id of the design
     */
    static async getPublicUrl (designId: string): Promise<string | ServiceError> {
        return axios.get(`${ process.env.REACT_APP_BACKEND_HOST }/design/${ designId }/design-url`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return DesignErrorHandler.getPublicUrl(error);
            });
    }

    /**
     * Retrieves the dimensions of a design from the backend.
     *
     * @param designId - The design id
     * @returns Either the dimensions if successful, or `undefined` if request fails.
     */
    static async getDimensions (designId: string): Promise<Dimensions | ServiceError> {
        const maxRetries = (process.env.REACT_APP_MAX_REQUEST_RETRIES || 1) as number;
        let response = undefined;
        let retries = 0;

        while (!response && retries < maxRetries) {
            response = await axios.get(`${ process.env.REACT_APP_BACKEND_HOST }/design/${ designId }/dimensions`)
                .then((response) => {
                    if (response.status === 202) {
                        return undefined;
                    }

                    return {
                        sizeX: response.data["sizeX"],
                        sizeY: response.data["sizeY"],
                        sizeZ: response.data["sizeZ"],
                        manifold: response.data["manifold"],
                        unit: "mm" as const,
                    } as Dimensions;
                })
                .catch((error) => {
                    return DesignErrorHandler.getDimensions(error);
                });

            if (!response) {
                const timeout = 5000;
                await new Promise((resolve) => setTimeout(resolve, timeout));
            }

            retries++;
        }

        if (!response) {
            return {
                statusCode: 500,
                message: t("messages:designs.dimensions.loadingError"),
            } as ServiceError;
        }

        return response;
    }

    /**
     * Retrieves compatible `print_options` for given design from the backend.
     *
     * @param designId - The id of the design
     * @returns Either the compatible options if successful, or `undefined` if request failed
     */
    static async getCompatiblePrintOptions (designId: string): Promise<CompatiblePrintOptions | ServiceError> {
        return axios.get(`${ process.env.REACT_APP_BACKEND_HOST }/design/${ designId }/compatible-options`)
            .then((response) => {
                return response.data as CompatiblePrintOptions;
            })
            .catch((error) => {
                return DesignErrorHandler.getCompatiblePrintOptions(error);
            });
    }


    /**
     * Method completely purges design from the backend.
     *
     * @param designId - Id of the design to be deleted
     * @returns - Promise containing a boolean indicating if the deletion was successful
     */
    static async purge (designId: string): Promise<boolean> {
        return axios.delete(`${ process.env.REACT_APP_BACKEND_HOST }/design/${ designId }/purge`)
            .then(() => {
                return true;
            })
            .catch(() => { return false; });
    }

    static async getAnalysis (designId: string): Promise<Analysis | ServiceError> {
        return axios.get(`${ process.env.REACT_APP_BACKEND_HOST }/design/${ designId }/analysis`)
            .then((response) => {
                return response.data as Analysis;
            })
            .catch((error) => {
                const analysis = error?.response?.data?.detail?.analysis;

                if (analysis !== undefined) {
                    return analysis as Analysis;
                }

                return DesignErrorHandler.getAnalysis(error);
            });
    }
}
