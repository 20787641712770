import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
    Icon,
    List,
    Popup,
} from "semantic-ui-react";

import { isServiceError } from "../../errors/types";
import { designStore } from "../../redux/slices/design";
import { DesignService } from "../../service/design";
import { DesignState } from "../../states/design";
import { Analysis } from "../../states/print";

export default function DesignAnalysis (props: { design: DesignState }): React.ReactElement {
    const dispatch = useDispatch();
    const [ analysisLoaded, setAnalysisLoaded ] = useState(false);
    const [ showAnalysis, setShowAnalysis ] = useState(false);
    const [ t ] = useTranslation([ "designs" ]);

    const [ designAnalysis, setDesignAnalysis ] = useState<Analysis>({
        manifold: false,
        dimensionValidationStatus: 1,
        hasCompatiblePrintOptions: false,
        validNumberOfParts: false,
    });

    useEffect(() => {
        if (props.design.dimensions && !analysisLoaded) {
            loadAnalysis();
        }
    }, [ props.design.dimensions ]);

    useEffect(() => {
        if (analysisLoaded && hasError()) {
            setShowAnalysis(true);
        }
    }, [ analysisLoaded ]);

    async function loadAnalysis (): Promise<void> {
        if (!props.design.id) {
            return;
        }

        const response = await DesignService.getAnalysis(props.design.id);
        if (isServiceError(response)) {
            setAnalysisLoaded(true);
            dispatch(designStore.setError({ fileAlias: props.design.fileAlias, error: response.message }));
            return;
        }

        setDesignAnalysis(response);
        setAnalysisLoaded(true);
    }

    function isLoading (): boolean {
        return !props.design.error && (!props.design.dimensions || !props.design.compatiblePrintOptions);
    }

    function hasError (): boolean {
        const hasError =  !designAnalysis.manifold ||
            designAnalysis.dimensionValidationStatus != 0 ||
            !designAnalysis.hasCompatiblePrintOptions ||
            !designAnalysis.validNumberOfParts;

        return hasError;
    }

    /** Match possible values against backend */
    function dimensionValidationStatusInfoText (): string {
        switch(designAnalysis.dimensionValidationStatus) {
        case 0:
            return t("designs:analysis.dimensionValidationStatus.valid");
        case 1:
            return t("designs:analysis.dimensionValidationStatus.invalid");
        case 2:
            return t("designs:analysis.dimensionValidationStatus.invalidTooSmall");
        case 3:
            return t("designs:analysis.dimensionValidationStatus.invalidTooLarge");
        default:
            return t("designs:analysis.dimensionValidationStatus.invalid");

        }
    }

    return (
        <>
            {
                isLoading() ? (
                    <Icon
                        name="circle notch"
                        loading
                    />
                ) :

                    <Popup
                        wide="very"
                        open={showAnalysis}
                        on="hover"
                        onOpen={(): void => setShowAnalysis(true)}
                        onClose={(): void => setShowAnalysis(false)}
                        hideOnScroll
                        trigger={
                            hasError() ?
                                <Icon
                                    name="warning sign"
                                    color="red"
                                /> :
                                <Icon
                                    name="check circle"
                                    color="green"
                                />
                        }
                        content={
                            <List>
                                <List.Item>
                                    <List.Icon
                                        name={designAnalysis.manifold ? "check circle" : "warning circle"}
                                        color={designAnalysis.manifold ? "green" : "red"}
                                    />
                                    <List.Content>
                                        <List.Header>
                                            {t("designs:analysis.manifold.title")}
                                        </List.Header>
                                        <List.Description>
                                            {
                                                designAnalysis.manifold ?
                                                    t("designs:analysis.manifold.valid") :
                                                    t("designs:analysis.manifold.invalid")
                                            }
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon
                                        name={designAnalysis.dimensionValidationStatus == 0 ? "check circle" : "warning circle"}
                                        color={designAnalysis.dimensionValidationStatus == 0 ? "green" : "red"}
                                    />
                                    <List.Content>
                                        <List.Header>
                                            {t("designs:analysis.dimensionValidationStatus.title")}
                                        </List.Header>
                                        <List.Description>
                                            {dimensionValidationStatusInfoText()}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon
                                        name={designAnalysis.hasCompatiblePrintOptions ? "check circle" : "warning circle"}
                                        color={designAnalysis.hasCompatiblePrintOptions ? "green" : "red"}
                                    />
                                    <List.Content>
                                        <List.Header>
                                            {t("designs:analysis.hasCompatiblePrintOptions.title")}
                                        </List.Header>
                                        <List.Description>
                                            {
                                                designAnalysis.hasCompatiblePrintOptions ?
                                                    t("designs:analysis.hasCompatiblePrintOptions.valid") :
                                                    t("designs:analysis.hasCompatiblePrintOptions.invalid")
                                            }
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon
                                        name={designAnalysis.validNumberOfParts ? "check circle" : "warning circle"}
                                        color={designAnalysis.validNumberOfParts ? "green" : "red"}
                                    />
                                    <List.Content>
                                        <List.Header>
                                            {t("designs:analysis.validNumberOfParts.title")}
                                        </List.Header>
                                        <List.Description>
                                            {
                                                designAnalysis.validNumberOfParts ?
                                                    t("designs:analysis.validNumberOfParts.valid") :
                                                    t("designs:analysis.validNumberOfParts.invalid")
                                            }
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        }
                    />

            }

        </>
    );
}
